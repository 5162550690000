import { inject } from "@angular/core";
import type {
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   Routes,
   UrlTree,
} from "@angular/router";
import { Router } from "@angular/router";
import { isMobile } from "lim-ui";
import { AppHousingComponent } from "src/app/shared/components/app-housing.component";
import { flagGuard, Flags } from "src/app/shared/services/launch-flags";
import { superUserGuard } from "src/app/shared/services/routeGuards/super-user.guard";

const defaultRoute = isMobile() ? "/mobileDashboard" : "/dashboard";

const routes: Routes = [
   {
      path: "",
      component: AppHousingComponent,
      data: { routeName: "appHousing" },
      children: [
         {
            path: "leave-review",
            loadChildren: async () =>
               import("src/app/users/components/review-page/review.routes"),
            data: { routeName: "review" },
         },
         {
            path: "userManagement",
            loadChildren: async () =>
               import(
                  "src/app/users/components/userManagementPage/userManagement.routes"
               ),
            data: { routeName: "userManagement" },
         },
         {
            path: "profileManagement/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/users/components/profileManagementPage/profileManagement.routes"
               ),
            data: { routeName: "profileManagement" },
         },
         {
            path: "completedTasks/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/completed-tasks-page/completed-tasks-page.routes"
               ),
            data: { routeName: "completedTasks" },
         },
         {
            path: "assetManagement/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/assets/components/assetManagement/asset-management.routes"
               ),
            data: { routeName: "assetManagement" },
         },
         {
            path: "partsManagement/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/parts/components/partsManagementPage/partsManagement.routes"
               ),
            data: { routeName: "partsManagement" },
         },
         {
            path: "pmManagement/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/planned-maintenance-management-page/planned-maintenance-management.routes"
               ),
            data: { routeName: "pmManagement" },
         },
         {
            path: "locationManagement",
            loadChildren: async () =>
               import(
                  "src/app/locations/components/locationManagementPage/locationManagement.routes"
               ),
            data: { routeName: "locationManagement" },
         },
         {
            path: "settings",
            loadChildren: async () =>
               import("src/app/settings/components/settings-page/settings.routes"),
            data: { routeName: "settings" },
         },
         {
            path: "inactiveWarning",
            loadChildren: async () =>
               import(
                  "src/app/shared/components/global/inactive-warning/inactive-warning.routes"
               ),
            data: { routeName: "inactiveWarning" },
         },
         {
            path: "mobileAssets/:assetID/:locationID",
            loadChildren: async () =>
               import("src/app/assets/components/mobileAssetsPage/mobileAssets.routes"),
            data: { routeName: "mobileAssets" },
         },
         {
            path: "mobilePart/:partID/:locationID",
            loadChildren: async () =>
               import("src/app/parts/components/mobilePartPage/mobilePart.routes"),
            data: { routeName: "mobilePart" },
         },
         {
            path: "demoSwitchBoard/:page",
            loadChildren: async () =>
               import(
                  "src/app/shared/components/global/demoSwitchBoardPage/demoSwitchBoard.routes"
               ),
            data: { routeName: "demoSwitchBoard" },
         },
         {
            path: "loggedInAsExternalUser",
            loadChildren: async () =>
               import(
                  "src/app/users/components/loggedInAsExternalUserPage/loggedInAsExternalUser.routes"
               ),
            data: { routeName: "loggedInAsExternalUser" },
         },
         {
            path: "thanks/:hint",
            loadChildren: async () =>
               import("src/app/tasks/components/thanksPage/thanks.routes"),
            data: { routeName: "thanks" },
         },
         {
            path: "vendorManagement/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/vendors/components/vendorManagementPage/vendorManagement.routes"
               ),
            data: { routeName: "vendorManagement" },
         },
         {
            path: "poManagement/:locationID",
            loadChildren: async () =>
               import("src/app/purchasing/pos/poManagementPage/poManagement.routes"),
            data: { routeName: "poManagement" },
         },
         {
            path: "prManagement/:locationID",
            loadChildren: async () =>
               import("src/app/purchasing/bills/prManagementPage/prManagement.routes"),
            data: { routeName: "prManagement" },
         },
         {
            path: "budgetManagement/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/purchasing/budgets/budgetManagementPage/budgetManagement.routes"
               ),
            data: { routeName: "budgetManagement" },
         },
         {
            path: "singlePO/:poID",
            loadChildren: async () =>
               import("src/app/purchasing/pos/singlePOPage/singlePo.routes"),
            data: { routeName: "singlePO" },
         },
         {
            path: "singlePR/:prID",
            loadChildren: async () =>
               import("src/app/purchasing/bills/singlePRPage/singlePr.routes"),
            data: { routeName: "singlePR" },
         },
         {
            path: "externalPOWorkflowStep/:hash/:direction/:id/:poID/:email/:code",
            loadChildren: async () =>
               import(
                  "src/app/purchasing/pos/externalPOWorkFlowStepPage/externalPoWorkflowStep.routes"
               ),
            data: { routeName: "externalPOWorkflowStep" },
         },
         {
            path: "externalPRStep/:hash/:direction/:prID/:email/:code",
            loadChildren: async () =>
               import(
                  "src/app/purchasing/bills/externalPRStepPage/externalPrStep.routes"
               ),
            data: { routeName: "externalPRStep" },
         },
         {
            path: "problem/:code/:locationID/:assetID",
            loadChildren: async () =>
               import("src/app/tasks/components/workRequests/problemPage/problem.routes"),
            data: { routeName: "problem" },
         },
         {
            path: "loc-problem/:code/:locationID",
            loadChildren: async () =>
               import("src/app/tasks/components/workRequests/problemPage/problem.routes"),
            data: { routeName: "loc-problem" },
         },
         {
            path: "mobile-loc-problem/:code/:locationID",
            loadChildren: async () =>
               import("src/app/tasks/components/workRequests/problemPage/problem.routes"),
            data: { routeName: "mobile-loc-problem" },
         },
         {
            path: "global-problem/:code",
            loadChildren: async () =>
               import("src/app/tasks/components/workRequests/problemPage/problem.routes"),
            data: { routeName: "global-problem" },
         },
         {
            path: "mobile-global-problem/:code",
            loadChildren: async () =>
               import("src/app/tasks/components/workRequests/problemPage/problem.routes"),
            data: { routeName: "mobile-global-problem" },
         },
         {
            path: "setupWorkRequests",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/workRequests/setupWorkRequestsPage/setupWorkRequests.routes"
               ),
            data: { routeName: "setupWorkRequests" },
         },
         {
            // Nested Route for Asset Templates suggested here: https://stackoverflow.com/a/48386859 to fix this angular bug here: https://github.com/angular/angular/issues/21702
            path: "assetTemplates",
            data: { routeName: "assetTemplates" },
            canActivate: [
               async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                  flagGuard(Flags.ASSET_TEMPLATES, defaultRoute, route, state),
            ],
            children: [
               {
                  path: "",
                  loadChildren: async () =>
                     import(
                        "src/app/assets/components/asset-templates-page/asset-templates.page.routes"
                     ),
                  canActivate: [
                     async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                        superUserGuard(route, state, defaultRoute),
                  ],
               },
            ],
         },
         {
            path: "locationDashboard/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/locationDashboardPage/locationDashboard.routes"
               ),
            data: { routeName: "locationDashboard" },
         },
         {
            path: "mobileDashboard",
            loadChildren: async () =>
               import(
                  "src/app/dashboards/components/mobileDashboardPage/mobileDashboard.routes"
               ),
            data: { routeName: "mobileDashboard" },
         },
         {
            path: "mobileTasks",
            loadChildren: async () =>
               import("src/app/tasks/components/mobileTasksPage/mobile-tasks.routes"),
            data: { routeName: "mobileTasks" },
         },
         {
            path: "viewExternalTask/:link",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/viewExternalTaskPage/viewExternalTask.routes"
               ),
            data: { routeName: "viewExternalTask" },
         },
         {
            path: "viewExternalTask/:link/:customerID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/viewExternalTaskPage/viewExternalTask.routes"
               ),
            data: { routeName: "viewExternalTask" },
         },
         {
            path: "task/:taskID/:locationID",
            loadChildren: async () =>
               import("src/app/tasks/components/singleTaskPage/singleTask.routes"),
            data: { routeName: "task" },
         },
         {
            path: "check-work-requests/:code/:email/:hash/:locationID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/workRequests/checkWorkRequestsPage/checkWorkRequests.routes"
               ),
            data: { routeName: "check-work-requests" },
         },
         {
            path: "check-work-requests/:code/:email/:hash/:locationID/:checklistID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/workRequests/checkWorkRequestsPage/checkWorkRequests.routes"
               ),
            data: { routeName: "check-work-requests" },
         },
         {
            path: "startTaskTemplate/:checklistID/:assetID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/startTaskTemplatePage/startTaskTemplate.routes"
               ),
            data: { routeName: "startTaskTemplate" },
         },
         {
            path: "flipPMSchedule/:checklistID",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/flipPMSchedulePage/flipPMSchedule.routes"
               ),
            data: { routeName: "flipPMSchedule" },
         },
         {
            path: "wr/:page",
            loadChildren: async () =>
               import(
                  "src/app/tasks/components/workRequests/wrRouterPage/wrRouter.routes"
               ),
            data: { routeName: "wr" },
         },
         {
            path: "receiveMobilePO/:poID",
            loadChildren: async () =>
               import(
                  "src/app/purchasing/pos/receiveMobilePOModal/receiveMobilePo.routes"
               ),
            data: { routeName: "receiveMobilePO" },
         },
         {
            path: "taskList",
            loadChildren: async () =>
               import("src/app/tasks/components/taskListPage/taskList.routes"),
            data: { routeName: "taskList" },
         },
         {
            path: "tasks/:locationID",
            loadChildren: async () =>
               import("src/app/tasks/components/tasksPage/tasks.routes"),
            data: { routeName: "tasks" },
         },
         {
            path: "dashboard",
            loadChildren: async () =>
               import("src/app/dashboards/components/dashboardPage/dashboard.routes"),
            data: { routeName: "dashboard" },
         },
         {
            path: "customDashboard",
            loadChildren: async () =>
               import(
                  "src/app/dashboards/components/customDashboardsPage/customDashboards.routes"
               ),
            data: { routeName: "customDashboard" },
         },
         {
            path: "customDashboardMobile",
            loadChildren: async () =>
               import(
                  "src/app/dashboards/components/customDashboardsPage/customDashboards.routes"
               ),
            data: { routeName: "customDashboardMobile" },
         },
         {
            path: "mapManagementLegacy/:map",
            loadChildren: async () =>
               import(
                  "src/app/maps/components/map-management-page/map-management-page-legacy/map-management-legacy.routes"
               ),
            data: { routeName: "mapManagementLegacy" },
         },
         {
            path: "mapManagement/:map",
            loadChildren: async () =>
               import(
                  "src/app/maps/components/map-management-page/map-management.routes"
               ),
            canActivate: [
               async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                  flagGuard(Flags.JIT_CT_MAP, "mapManagementLegacy", route, state),
            ],
            data: { routeName: "mapManagement" },
         },
         {
            path: "subscription",
            loadChildren: async () =>
               import(
                  "src/app/subscriptions/components/subscriptionPage/subscription.routes"
               ),
            data: { routeName: "subscription" },
         },
         {
            path: "mobileSubscription",
            loadChildren: async () =>
               import(
                  "src/app/subscriptions/components/subscriptionPage/subscription.routes"
               ),
            data: { routeName: "mobileSubscription" },
         },
         {
            path: "approveCheckOut/:checkOutID",
            loadChildren: async () =>
               import(
                  "src/app/assets/components/approveCheckOutPage/approveCheckOut.routes"
               ),
            data: { routeName: "approveCheckOut" },
         },
         {
            path: "authenticateGIS",
            loadChildren: async () =>
               import(
                  "src/app/settings/components/gisAuthentication/authenticateGIS.routes"
               ),
            data: { routeName: "authenticateGIS" },
         },
         {
            path: "roleManagement",
            redirectTo: "/settings/roleManagement",
         },
         {
            path: "limbleConfig",
            redirectTo: "/settings/limbleConfig",
         },
         {
            path: "ssoManagement",
            redirectTo: "/settings/ssoManagement",
         },
         {
            path: "logs",
            redirectTo: "/settings/logs",
         },
         {
            path: "taskList/:taskID",
            // This is a hacky way to redirect to the new taskList route. We do this because
            // Angular doesn't have an easy way to move the path params from the old route to
            // the query params in the new route.
            canActivate: [
               (route: ActivatedRouteSnapshot): UrlTree => {
                  return inject(Router).createUrlTree(["main", "taskList"], {
                     queryParams: { taskID: route.paramMap.get("taskID") },
                  });
               },
            ],
            children: [],
         },
         {
            path: "**",
            redirectTo: defaultRoute,
         },
      ],
   },
];

export default routes;

export const settingRoutes = ["roleManagement", "limbleConfig", "ssoManagement", "logs"];

export const mobileRoutes = [
   "mobile-loc-problem",
   "mobile-global-problem",
   "customDashboardMobile",
   "mobileSubscription",
   "mobileDashboard",
   "mobileTasks",
   "mobileAssets",
   "mobileVendor",
   "receiveMobilePO",
];

// TODO:(CMMS-1694) - Refactor this into an enum and use in side nav instead of hard coding routes
export const locationRoutes = [
   "loc-problem",
   "locationDashboard",
   "tasks",
   "completedTasks",
   "completedTasksLegacy",
   "assetManagement",
   "partsManagement",
   "pmManagement",
   "pmManagementLegacy",
   "profileManagement",
   "vendorManagement",
   "poManagement",
   "prManagement",
   "budgetManagement",
];
